/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./auth.component";
import * as i2 from "./auth.service";
import * as i3 from "@angular/router";
var styles_AuthComponent = [];
var RenderType_AuthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AuthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i0.ɵdid(1, 114688, null, 0, i1.AuthComponent, [i2.AuthService, i3.Router, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthComponentNgFactory = i0.ɵccf("ng-component", i1.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
