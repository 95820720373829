import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DateInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = this.modifyRequest(req);
    return next.handle(modifiedReq);
  }

  private modifyRequest(req: HttpRequest<any>): HttpRequest<any> {
    const params = req.params;
    if (params.has('by_period[started_at]') && params.has('by_period[ended_at]')) {
      const startDate = new Date(params.get('by_period[started_at]'));
      const endDate = new Date(params.get('by_period[ended_at]'));

      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        params.set('by_period[started_at]', startDate.toISOString());
        params.set('by_period[ended_at]', endDate.toISOString());
      } else {
        params.delete('by_period[started_at]');
        params.delete('by_period[ended_at]');
      }
    }

    return req.clone({ params });
  }
}
