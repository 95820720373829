import {Component, OnInit} from '@angular/core';
import {URLSearchParams} from '@angular/http';

import {AuthService} from './auth.service';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  template: ''
})
export class AuthComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const auth = {};
    const queryParams = this.route.snapshot.fragment;
    const params = new URLSearchParams(queryParams.split('#').toString());

    params.paramsMap.forEach((value, key) => auth[key.toString()] = value[0]);

    this.authService.saveTokenData(auth);
    this.router.navigate(['main/operational/events']);
  }
}
