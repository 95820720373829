import { HttpClient } from '@angular/common/http';
import { Router, UrlSerializer } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConstants } from '../../shared/constants.service';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var KEY = 'auth';
var AuthService = /** @class */ (function () {
    function AuthService(http, router, serializer) {
        this.http = http;
        this.router = router;
        this.serializer = serializer;
        this.authorization_endpoint = AppConstants.authUrl + '/oauth/authorize';
        this.logout_endpoint = AppConstants.authUrl + '/users/sign_out';
        this.redirect_uri = window.location.origin + '/auth/callback';
        this.queryParams = {
            client_id: environment.oAuthClientId,
            response_type: 'token',
            redirect_uri: this.redirect_uri
        };
    }
    AuthService.prototype.signIn = function () {
        var queryParams = this.queryParams;
        var tree = this.router.createUrlTree([''], { queryParams: queryParams, skipLocationChange: true });
        window.location.href = this.authorization_endpoint + tree;
    };
    AuthService.prototype.signOut = function () {
        window.localStorage.removeItem(KEY);
        location.href = this.logout_endpoint;
    };
    AuthService.prototype.silentRefreshToken = function () {
        var _this = this;
        return new Observable(function (observer) {
            var tree = _this.router.createUrlTree([''], { queryParams: _this.queryParams, skipLocationChange: true });
            var sessionIframe = window.document.createElement('iframe');
            sessionIframe.style.display = 'none';
            window.document.body.appendChild(sessionIframe);
            sessionIframe.src = _this.authorization_endpoint + tree;
            sessionIframe.onload = function () { observer.next(_this.getAccessToken()); };
            sessionIframe.onerror = function () { observer.error('Refresh error'); };
        });
    };
    AuthService.prototype.getAccessToken = function () {
        var payload = this.getTokenPayload();
        if (!!payload) {
            return payload.access_token;
        }
        return '';
    };
    AuthService.prototype.saveTokenData = function (data) {
        var auth = Object.assign({ created_at: Date.now() }, data);
        auth = JSON.stringify(auth);
        window.localStorage.setItem(KEY, auth);
    };
    AuthService.prototype.getTokenPayload = function () {
        var payload = window.localStorage.getItem(KEY);
        if (!!payload) {
            return JSON.parse(payload);
        }
        return null;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i2.UrlSerializer)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
