import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { ToastDefaults } from 'ng-snotify';
import { CustomCurrencyMaskConfig } from './shared/components-config/currency-mask/custom-currency-mask-config';
registerLocaleData(localePt, 'pt');
var ɵ0 = ToastDefaults, ɵ1 = CustomCurrencyMaskConfig;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
