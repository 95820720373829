import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Router, UrlSerializer} from '@angular/router';
import {Observable, of, timer} from 'rxjs';
import {AppConstants} from '../../shared/constants.service';
import {environment} from '../../../environments/environment';

const KEY = 'auth';

@Injectable({providedIn: 'root'})
export class AuthService {

  private authorization_endpoint = AppConstants.authUrl + '/oauth/authorize';
  private logout_endpoint = AppConstants.authUrl + '/users/sign_out';
  private redirect_uri = window.location.origin + '/auth/callback';

  private queryParams = {
    client_id: environment.oAuthClientId,
    response_type: 'token',
    redirect_uri: this.redirect_uri
  };

  constructor(private http: HttpClient, private router: Router, private serializer: UrlSerializer) {
  }

  signIn() {
    const queryParams = this.queryParams;
    const tree = this.router.createUrlTree([''], {queryParams: queryParams, skipLocationChange: true});
    window.location.href = this.authorization_endpoint + tree;
  }

  signOut() {
    window.localStorage.removeItem(KEY);
    location.href = this.logout_endpoint;
  }

  silentRefreshToken() {
    return new Observable((observer) => {
      const tree = this.router.createUrlTree([''], {queryParams: this.queryParams, skipLocationChange: true});
      const sessionIframe = window.document.createElement('iframe');

      sessionIframe.style.display = 'none';

      window.document.body.appendChild(sessionIframe);

      sessionIframe.src = this.authorization_endpoint + tree;
      sessionIframe.onload = () => { observer.next(this.getAccessToken()); };
      sessionIframe.onerror = () => { observer.error('Refresh error'); };
    });
  }

  getAccessToken() {
    const payload = this.getTokenPayload();

    if (!!payload) {
      return payload.access_token;
    }

    return '';
  }

  saveTokenData(data) {
    let auth = Object.assign({created_at: Date.now()}, data);
    auth = JSON.stringify(auth);
    window.localStorage.setItem(KEY, auth);
  }

  private getTokenPayload() {
    const payload = window.localStorage.getItem(KEY);
    if (!!payload) {
      return JSON.parse(payload);
    }

    return null;
  }
}
